.ant-modal-content > div.thumb-container {
  height: 200px;
  width: 260px;
  background-color: red;
}
.ant-modal-content > div.doc-tile {
  height: 200px;
  width: 200px;
}
.ant-modal-content > div.image-doc-container {
}
.ant-modal-content > div.other-doc-container {
  background-color: yellow;
}
.ant-modal-content > div.button-doc-container {
}
